import React from "react";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  className: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, type, className, disabled }) => {
  return (
    <button type={type} className={`button-${className}`} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
